<template>
  <side-bar>
    <router-view />
  </side-bar>
</template>
<script>
import SideBar from "../layouts/SideBar.vue";

export default {
  components: { SideBar },
};
</script>
<style lang="scss"></style>
